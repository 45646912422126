.modal {
    font-size: 12px;
  }
  .popup-content {
    width: auto !important;
    padding: 30px !important;
  }
  .modal button {
    @apply hidden sm:inline-flex text-white justify-center w-full bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center items-center

  }
  .modal > .header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px;
  }
  .modal > .content {
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal > .actions {
    width: 100%;
    padding: 15px 0 0px;
    margin: auto;
    text-align: center;
  }
  .modal > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
  }