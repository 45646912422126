.dropdown:focus-within .dropdown-menu {
    opacity:1;
    transform: translate(0) scale(1);
    visibility: visible;
}

.organisation-select {
    select {
        padding-right: 2rem;
    }

    svg {
        right: 0.5rem;
        z-index: -1;
    }
}